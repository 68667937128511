export const WeddingSliderData = [
    {
      image: './images/wedding1.jpg' 
    },
    {
        image: './images/wedding2.jpg'  
      },
      {
        image: './images/wedding3.jpg'  
      },
      {
        image: './images/wedding4.jpg'  
      },
      {
        image: './images/wedding5.jpg'  
      }

      
]