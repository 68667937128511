export const BaptismSliderData = [
    {
      image: './images/baptism1.jpg'  
    },
    {
        image: './images/baptism2.jpg'  
      },
      {
        image: './images/baptism3.jpg'  
      },
      {
        image: './images/baptism4.jpg'  
      },
      {
        image: './images/baptism5.jpg'  
      }

      
]