export const PregnancySliderData = [
    {
      image: './images/pregnancy1.jpg'  
    },
    {
        image: './images/pregnancy2.jpg'  
      },
      {
        image: './images/pregnancy3.jpg'  
      },
      {
        image: './images/pregnancy4.jpg'  
      },
      {
        image: './images/pregnancy5.jpg'  
      }

      
]