export const BabySliderData = [
    {
      image: './images/baby1.jpg'  
    },
    {
        image: './images/baby2.jpg'  
      },
      {
        image: './images/baby3.jpg'  
      },
      {
        image: './images/baby4.jpg'  
      },
      {
        image: './images/baby5.jpg'  
      }

      
]