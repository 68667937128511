export const FamilySliderData = [
    {
      image: './images/family1.jpg'  
    },
    {
        image: './images/family2.jpg'  
      },
      {
        image: './images/family3.jpg'  
      },
      {
        image: './images/family4.jpg'  
      },
      {
        image: './images/family5.jpg'  
      }

      
]