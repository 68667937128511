export const CoupleSliderData = [
  {
    image: "./images/couple1.jpg",
  },
  {
    image: "./images/couple2.jpg",
  },
  {
    image: "./images/couple3.jpg",
  },
  {
    image: "./images/couple4.jpg",
  },
  {
    image: "./images/couple5.jpg",
  },
];
